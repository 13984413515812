.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html{
  scroll-behavior: smooth;
}
@font-face {
  font-family: 'ABLE';
  src: url('/src/Assets/font/test-calibre-regular.woff2') format('woff');
  /* src: url('/src/Assets/font/test-calibre-regular.woff2') format('woff'); */
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
p,span,a,div{
  font-family: 'ABLE';
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 35%); 
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 10px #b46eff; 
}
.css-13cymwt-control{
  border-color: none;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
  border: 0 !important;
  border-radius: 8px !important;
  padding: 4px;
  font-size: 18px;
}
.css-t3ipsp-control{
  border-color: none;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
  border: 0 !important;
  border-radius: 8px !important;
  padding: 4px;
}
.custom-image{
  /* background-color: #a783cf; */
  /* background-image: url('./Assets/Images/cart_bg.png'); */
  background-color:  #F7F4F4;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  border: 1px solid rgb(109 79 160);
}

.title{
  color: rgb(109 79 160);
  font-weight: 600;
}
.custom-thumbnail{
  /* background-color: #a783cf !important; */
  /* background-image: url('./Assets/Images/cart_bg.png') !important; */
  background-color:  #F7F4F4;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color:  #F7F4F4;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 5px;
}
.image-gallery-slide .image-gallery-image{
  /* width:50% !important; */
}
.image-gallery-thumbnails .image-gallery-thumbnails-container{
  text-align: start !important;
}
 
.image-gallery-thumbnails {
  margin-top: 10px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image{
  /* width: 60% !important; */
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus{
  border: 3px solid rgb(109 79 160) !important;
}
.image-gallery-thumbnail{
  border: 3px solid lightgray !important;
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #D0A3FF;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.grecaptcha-badge {
  position: relative!important;
  bottom: -50px!important;
  right: -130px!important;
}
@media (max-width:600px) {
  .grecaptcha-badge {
    left: 10px !important;
  }
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.react-pdf__Document{
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.react-pdf__Page__annotations{
  display: none;
}

.blog_1 {
  transition: 0.3s all;
}

.blog_1:hover .blog_hover {
  display: block;
  animation: fadeIn 0.5s forwards;
}

.blog_hover {
  display: none;
}

/* Fade-in animation keyframes */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(60px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
